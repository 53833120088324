export var isPublicRoute = function () {
    if (window.location.pathname.match('/certificate/*'))
        return true;
    // if (window.location.pathname.match('/extension/*')) return true;
    if (window.location.pathname.match('/logout'))
        return true;
    if (window.location.pathname.match('/thanks'))
        return true;
    if (window.location.pathname.match('/plan'))
        return true;
    if (window.location.pathname.match('/onboarding-questions'))
        return true;
    if (window.location.pathname.match('/register'))
        return true;
    return false;
};
export var isShouldLogout = function () {
    if (window.location.pathname.match('/certificate/*'))
        return false;
    if (window.location.pathname.match('/login'))
        return false;
    if (window.location.pathname.match('/register'))
        return false;
    return true;
};
