const ThemeColors = {
  themeBackgroundColor: '#152c5b',
  themeBackgroundLightColor: '#233966',
  themeBackgroundHeavyColor: '#24447e',
  navigationColor: '#305093',
  navigationColorLight: '#445880',
  navigationColorHeavy: '#24447e',
  secondaryColor: '#ff9100',
  secondaryLightColor: '#ffa32a',
  secondaryHeavyColor: '#f38a00',
  borderRadius: '5px',
  borderColor: '#e1edff',
  lightBackgroundColor: '#f8fbfe',
  secondaryButtonColor: '#1570EF',
  gray800: '#1D2939',
};

export default ThemeColors;
