import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './RedirectPage.module.css';
import { Col, message, Row } from 'antd';
import unsupported from '../../images/download.png';
import firefox from '../../images/firefox.png';
import safari from '../../images/safari.png';
import google2 from '../../images/google2.png';
var RedirectPage = function () {
    var onCopy = function () {
        message.success('link copied to clipboard');
        navigator.clipboard.writeText("".concat(window.location.host));
    };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx("img", { src: unsupported, height: 40, alt: "404" }), _jsx("h2", __assign({ className: styles.title }, { children: "You are on an unsupported browser" })), _jsxs("div", __assign({ className: styles.guide }, { children: [_jsx("h4", { children: "Follow the steps below to open the Careerflow application" }), _jsxs("div", __assign({ className: styles.steps }, { children: [_jsx("div", __assign({ className: styles.step_content }, { children: _jsxs("span", { children: [_jsx("b", { children: "Step 1:" }), " Copy this link", ' ', _jsxs("div", __assign({ className: styles.link }, { children: [_jsx("input", { className: styles.input, disabled: true, value: "".concat(window.location.host) }), _jsx("button", __assign({ className: styles.btn, onClick: function () { return onCopy(); } }, { children: "Copy Link" }))] }))] }) })), _jsxs("div", __assign({ className: styles.step_content }, { children: [_jsx("b", { children: "Step 2:" }), " Open the link in a supported browser such as Chrome, Safari, or Firefox to search for Hiring Managers and Recruiters at your dream companies!"] }))] })), _jsxs(Row, __assign({ className: styles.browser, gutter: { xs: 8, sm: 16, md: 24, lg: 32 }, justify: "center" }, { children: [_jsx(Col, __assign({ className: "gutter-row", span: 6 }, { children: _jsx("img", { src: google2, height: 60, alt: "404" }) })), _jsx(Col, __assign({ className: "gutter-row", span: 6 }, { children: _jsx("img", { src: safari, height: 60, alt: "404" }) })), _jsx(Col, __assign({ className: "gutter-row", span: 6 }, { children: _jsx("img", { src: firefox, height: 60, alt: "404" }) }))] }))] }))] })));
};
export default RedirectPage;
