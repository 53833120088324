import axios from 'axios';
import { config, instituteAppApiPath } from '../utils/apiPaths';
var getInstituteFromWhiteLabeledUrl = function (whiteLabeledUrl) {
    var baseURL = config.baseUrl + "/instituteApp/white-label/".concat(whiteLabeledUrl);
    return axios.get(baseURL);
};
var checkIfStudentInvited = function (instituteId, studentId) {
    var baseURL = instituteAppApiPath +
        "/".concat(instituteId, "/verifyInstituteStudent") +
        (studentId ? "/".concat(studentId) : '');
    return axios.get(baseURL);
};
var verifyInstituteCoach = function (instituteId, coachId) {
    var baseURL = instituteAppApiPath +
        "/".concat(instituteId, "/verifyInstituteCoach") +
        (coachId ? "/".concat(coachId) : '');
    return axios.get(baseURL);
};
var instituteAppService = {
    getInstituteFromWhiteLabeledUrl: getInstituteFromWhiteLabeledUrl,
    checkIfStudentInvited: checkIfStudentInvited,
    verifyInstituteCoach: verifyInstituteCoach,
};
export default instituteAppService;
