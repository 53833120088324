import { __awaiter, __generator } from "tslib";
import axios from 'axios';
import { auth } from '../utils/firebase/firebaseIndex';
axios.interceptors.request.use(function (config) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(config && config.headers && auth.currentUser)) return [3 /*break*/, 2];
                return [4 /*yield*/, auth.currentUser.getIdToken()];
            case 1:
                accessToken = _a.sent();
                config.headers['Authorization'] = "Bearer ".concat(accessToken);
                _a.label = 2;
            case 2: return [2 /*return*/, config];
        }
    });
}); }, function (error) { return Promise.reject(error); });
