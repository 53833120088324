var innerConfig = {
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
};
export var config = innerConfig;
export var instituteAppApiPath = "".concat(config.baseUrl, "/instituteApp/institute");
export var stripeCheckoutApiPath = "".concat(config.baseUrl, "/faangpath/premiumSubscriptionCheckout");
export var webappErrorReportApiPath = "".concat(config.baseUrl, "/faangpath/error/webApp");
export var jobTrackerApiPath = "".concat(config.baseUrl, "/jobTracker");
export var faangpathApiPath = "".concat(config.baseUrl, "/faangpath");
export var saveQueryApiPath = "".concat(jobTrackerApiPath, "/saveQuery");
export var saveAllQueriesPath = "".concat(jobTrackerApiPath, "/queries");
export var userRoleApiPath = "".concat(jobTrackerApiPath, "/user-role");
export var userDetailApiPath = "".concat(jobTrackerApiPath, "/userdetail");
export var miscApiPath = "".concat(config.baseUrl, "/faangpath/misc");
export var shareQueryApiPath = "".concat(jobTrackerApiPath, "/shareQuery");
export var likeApiPath = "".concat(jobTrackerApiPath, "/like");
export var queryFeedbackApiPath = "".concat(jobTrackerApiPath, "/queryFeedback");
export var linkedinProfileApiPath = "".concat(config.baseUrl, "/chromeExtension/linkedinProfile");
export var aiServiceBasePath = "".concat(config.baseUrl, "/aiTools");
export var aiCoverLetterApiPath = "".concat(aiServiceBasePath, "/generate/cover-letter");
export var linkedinAboutApiPath = "".concat(aiServiceBasePath, "/generate/linkedin/about");
export var elevatorPitchApiPath = "".concat(aiServiceBasePath, "/generate/elevatorPitch");
export var linkedinHeadlineApiPath = "".concat(aiServiceBasePath, "/generate/linkedin/headline");
export var aiPostApiPath = "".concat(aiServiceBasePath, "/generate/linkedin-post");
export var aiParseApiPath = "".concat(aiServiceBasePath, "/generate/parse");
export var skillmatchApiPath = "".concat(jobTrackerApiPath, "/skillmatch");
export var imageUrlEndpoint = 'https://ik.imagekit.io/6jgghqyrto/';
export var fastApiDomainPath = "https://fastapi-chatbot-389115.el.r.appspot.com";
