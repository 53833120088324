import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DownOutlined, MenuOutlined, MenuUnfoldOutlined, } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space, Tag } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../auth';
import aboutGeneratorImg from '../../images/AboutGenerator.svg';
import headlineGeneratorImg from '../../images/HeadlineGenerator1.svg';
import linkedINPostGeneratorImg from '../../images/LinkedINPostGenerator.svg';
import careerflowlogo from '../../images/careerflowLogo.svg';
import coverLetterImg from '../../images/coverletterIcon.svg';
import elevatorPitchIcon from '../../images/elevatorPitchToolIcon.svg';
import mockInterviewImg from '../../images/mockinterviewMenuitem.svg';
import logo from '../../images/newLogoLight.png';
import premiumStar from '../../images/premiumStar.svg';
import starsIcon from '../../images/stars.svg';
import ThemeColors from '../../theme/themeColors';
import ProfileInfo from '../profileInfo/ProfileInfo';
import style from './Navbar.module.css';
import ensureHttps from '../../utils/ensureHttps';
export var INVITE_STATES;
(function (INVITE_STATES) {
    INVITE_STATES["accepted"] = "ACCEPTED";
    INVITE_STATES["invited"] = "INVITED";
    INVITE_STATES["canceled"] = "CANCELED";
    INVITE_STATES["deactivated"] = "DEACTIVATED";
})(INVITE_STATES || (INVITE_STATES = {}));
var Navbar = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var isMenuUnfolded = _a.isMenuUnfolded, setIsMenuUnfolded = _a.setIsMenuUnfolded, setUpgrade = _a.setUpgrade;
    var navigate = useNavigate();
    var t = useTranslation().t;
    var _q = useContext(AppContext), isMobile = _q.isMobile, userInfo = _q.userInfo, appConfig = _q.appConfig, institute = _q.institute, selectedInstitute = _q.selectedInstitute, isInstituteCoach = _q.isInstituteCoach;
    var items = [
        {
            label: (_jsxs("div", __assign({ className: style.menuItem }, { children: [_jsx("div", __assign({ className: style.menuIconAi }, { children: _jsx("img", { height: 40, style: { borderRadius: '120px' }, src: coverLetterImg, alt: 'cover letter icon' }) })), _jsx("div", { children: t('CoverLetter') })] }))),
            key: '1',
            onClick: function () {
                navigate('/cover-letter');
            },
        },
        {
            label: (_jsxs("div", __assign({ className: style.menuItem }, { children: [_jsx("div", __assign({ className: style.menuIconAi }, { children: _jsx("img", { height: 40, style: { borderRadius: '120px' }, src: linkedINPostGeneratorImg, alt: 'linked post generator icon' }) })), _jsx("div", { children: t('PostGenerator') })] }))),
            key: '2',
            onClick: function () {
                navigate('/post-generator');
            },
        },
    ];
    if (!((_b = institute === null || institute === void 0 ? void 0 : institute.disabledFeatures) === null || _b === void 0 ? void 0 : _b.includes('linkedin'))) {
        items.push.apply(items, [
            {
                label: (_jsxs("div", __assign({ className: style.menuItem }, { children: [_jsx("div", __assign({ className: style.menuIconAi }, { children: _jsx("img", { height: 40, style: { borderRadius: '120px' }, src: headlineGeneratorImg, alt: 'linkedin headline generator icon' }) })), _jsx("div", { children: t('LinkedinHeadlineGenerator') })] }))),
                key: '3',
                onClick: function () {
                    navigate('/linkedin-headline');
                },
            },
            {
                label: (_jsxs("div", __assign({ className: style.menuItem }, { children: [_jsx("div", __assign({ className: style.menuIconAi }, { children: _jsx("img", { height: 36, style: { borderRadius: '120px' }, src: aboutGeneratorImg, alt: 'linkedin about generator icon' }) })), _jsx("div", { children: t('LinkedinAboutSectionGenerator') })] }))),
                key: '4',
                onClick: function () {
                    navigate('/linkedin-about');
                },
            },
        ]);
    }
    if (((_d = (_c = appConfig === null || appConfig === void 0 ? void 0 : appConfig.features) === null || _c === void 0 ? void 0 : _c.elevatorPitch) === null || _d === void 0 ? void 0 : _d.enabled) === true)
        items.push({
            label: (_jsxs("div", __assign({ className: style.menuItem }, { children: [_jsxs("div", __assign({ className: style.menuIconAi }, { children: [_jsx("img", { height: 40, style: { borderRadius: '120px' }, src: elevatorPitchIcon, alt: 'elevator pitch icon' }), ' '] })), _jsx("div", { children: t('ElevatorPitch') }), _jsx(Tag, __assign({ color: "green", style: { marginLeft: '1rem' } }, { children: "New" }))] }))),
            key: '5',
            onClick: function () {
                navigate('/elevator-pitch');
            },
        });
    if (((_f = (_e = appConfig === null || appConfig === void 0 ? void 0 : appConfig.features) === null || _e === void 0 ? void 0 : _e.mockInterview) === null || _f === void 0 ? void 0 : _f.enabled) === true &&
        (!Object.keys(institute || {}).length ||
            (institute === null || institute === void 0 ? void 0 : institute.mockinterviewEnabled) === true)) {
        items.unshift({
            label: (_jsxs("div", __assign({ className: style.menuItem }, { children: [_jsx("div", __assign({ className: style.menuIconAi }, { children: _jsx("img", { height: 40, style: { borderRadius: '120px' }, src: mockInterviewImg, alt: 'mock interview icon' }) })), _jsx("div", { children: t('MockInterview') }), _jsx(Tag, __assign({ color: "green", style: { marginLeft: '1rem' } }, { children: "New" }))] }))),
            key: '0',
            onClick: function () {
                if (institute === null || institute === void 0 ? void 0 : institute.customMockInterviewUrl) {
                    return window.open(ensureHttps(institute === null || institute === void 0 ? void 0 : institute.customMockInterviewUrl));
                }
                navigate('/mock-interview');
            },
        });
    }
    return (_jsxs(Menu, __assign({ mode: "horizontal", defaultSelectedKeys: ['mail'], style: {
            lineHeight: '50px',
            minHeight: '50px',
            backgroundColor: ThemeColors.themeBackgroundColor,
            color: '#FFFFFF',
            position: 'fixed',
            width: '100%',
            border: '0px',
        }, id: "header_navbar" }, { children: [isMobile && (_jsx(Button, __assign({ className: style.menuIcon, onClick: function () { return setIsMenuUnfolded(function (menuState) { return !menuState; }); } }, { children: isMenuUnfolded ? _jsx(MenuOutlined, {}) : _jsx(MenuUnfoldOutlined, {}) }))), !isMobile &&
                ((isInstituteCoach ||
                    ((_g = userInfo === null || userInfo === void 0 ? void 0 : userInfo.invites) === null || _g === void 0 ? void 0 : _g[selectedInstitute]) === 'ACCEPTED') &&
                    (institute === null || institute === void 0 ? void 0 : institute.name) ? (_jsxs("div", __assign({ className: style.title }, { children: [_jsx("div", __assign({ className: style.careerflowDiv }, { children: _jsx("img", { src: careerflowlogo, height: 24, alt: "careerflow logo" }) })), _jsx("div", __assign({ className: style.imgDiv }, { children: _jsx("img", { src: institute === null || institute === void 0 ? void 0 : institute.logoUrl, height: 24, alt: "careerflow logo" }) })), _jsx("div", __assign({ className: style.instituteTitle }, { children: institute === null || institute === void 0 ? void 0 : institute.name }))] }))) : (_jsx("div", __assign({ className: style.title }, { children: _jsx("a", __assign({ href: "https://careerflow.ai/app" }, { children: _jsx("img", { src: logo, height: 30, style: {
                                marginRight: '1rem',
                                marginBottom: '5px',
                                marginLeft: '1rem',
                            }, alt: "careerflow logo" }) })) })))), _jsxs("div", __assign({ className: style.profile_div }, { children: [((_j = (_h = appConfig.features) === null || _h === void 0 ? void 0 : _h.ai) === null || _j === void 0 ? void 0 : _j.status) === 'enabled' &&
                        ((institute && !((_k = institute === null || institute === void 0 ? void 0 : institute.disabledFeatures) === null || _k === void 0 ? void 0 : _k.includes('aiTools'))) ||
                            !selectedInstitute) && (_jsx(Dropdown, __assign({ className: style.dropdown, menu: { items: items }, trigger: ['click'], placement: "bottomRight" }, { children: _jsx(Button, __assign({ className: "tertiary-btn", onClick: function (e) { return e.preventDefault(); } }, { children: _jsxs(Space, { children: [_jsx("img", { src: starsIcon, alt: "stars" }), _jsx("span", __assign({ style: { color: 'black' } }, { children: t('AITools') })), _jsx(DownOutlined, {})] }) })) }))), ((_m = (_l = appConfig.features) === null || _l === void 0 ? void 0 : _l.upgradeButton) === null || _m === void 0 ? void 0 : _m.status) === 'enabled' &&
                        (userInfo === null || userInfo === void 0 ? void 0 : userInfo.userType) !== 'premium' && (_jsx("div", { children: _jsx(Button, __assign({ onClick: function () { return setUpgrade(true); }, className: 'upgradePremiumButton' }, { children: t('UpgradetoPremium') })) })), ((_p = (_o = appConfig.features) === null || _o === void 0 ? void 0 : _o.ai) === null || _p === void 0 ? void 0 : _p.status) === 'enabled' &&
                        (userInfo === null || userInfo === void 0 ? void 0 : userInfo.userType) === 'premium' && (_jsxs("div", __assign({ className: 'premiumButton' }, { children: [_jsx("img", { style: { marginRight: '0.25rem' }, src: premiumStar, alt: "star" }), t('Premium')] }))), _jsx(ProfileInfo, {})] }))] })));
};
export default Navbar;
