var _a, _b;
import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import UserProvider from './auth';
import RedirectPage from './components/RedirectPage/RedirectPage';
import Fallback from './Fallback';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './i18n';
import axios from 'axios';
import { webappErrorReportApiPath } from './utils/apiPaths';
var root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
    (_a = document
        .querySelector('#gsc')) === null || _a === void 0 ? void 0 : _a.setAttribute('content', 'odSKtUMRASQiyFyKotptp4Mnx_Y0sLgvazb-j2tpS9E');
    (_b = document
        .querySelector('#gsc')) === null || _b === void 0 ? void 0 : _b.setAttribute('name', 'google-site-verification');
}
function FallbackComponent() {
    return _jsx(Fallback, {});
}
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        return { hasError: true };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        var errorDetails = {
            error: error.toString(),
            errorInfo: errorInfo.componentStack,
            url: window.location.href,
        };
        console.error(error, errorInfo);
        this.setState({ hasError: true });
        axios.post(webappErrorReportApiPath, errorDetails).catch(function (err) {
            console.log(err);
        });
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.hasError) {
            return _jsx(FallbackComponent, {});
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(Component));
var userAgent = window.navigator.userAgent.toLowerCase(), safari = /safari/.test(userAgent), ios = /iphone|ipod|ipad/.test(userAgent);
var rootWebView = ReactDOM.createRoot(document.getElementById('root-web-view'));
if ((ios && !safari) || (!ios && userAgent.includes('wv'))) {
    rootWebView.render(_jsx(RedirectPage, {}));
}
else {
    root.render(_jsx(ErrorBoundary, { children: _jsx(Router, { children: _jsx(UserProvider, { children: _jsx(App, {}) }) }) }));
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
