import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown, Spin } from 'antd';
import { getDownloadURL, ref } from 'firebase/storage';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../auth';
import GooggleIcon from '../../images/goggleicon.png';
import { logout, storage } from '../../utils/firebase/firebaseIndex';
import styles from './ProfileInfo.module.css';
import profileIcon from '../../images/profileicon.png';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, LogoutOutlined, QuestionCircleOutlined, SettingOutlined, SwapOutlined, UserOutlined, } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { handleOnKeyDown } from '../../utils/eventListeners';
import { InstituteFeatureFlags } from '../../constants/instituteFeatureToggles';
var ProfileInfo = function () {
    var _a, _b;
    var _c = useContext(AppContext), userInfo = _c.userInfo, user = _c.user, signUpOrSignInWithGoogleCall = _c.signUpOrSignInWithGoogleCall, institute = _c.institute;
    var isPhotoLoading = useState(false)[0];
    var _d = useState(''), profilePic = _d[0], setProfilePic = _d[1];
    var isInstituteCoach = (_a = Object.keys(userInfo.instituteRoles || {})) === null || _a === void 0 ? void 0 : _a[0];
    var t = useTranslation().t;
    var navigate = useNavigate();
    var getDownloadUrl = function (profilePath) {
        if (profilePath !== '' || profilePath !== null) {
            setTimeout(function () {
                var storageRef = ref(storage, profilePath);
                getDownloadURL(storageRef)
                    .then(function (url) {
                    setProfilePic(url);
                })
                    .catch(function (err) {
                    console.log('err', err);
                });
            }, 1000);
        }
    };
    useEffect(function () {
        if (userInfo.profilePic && userInfo.profilePic !== '' && !isPhotoLoading) {
            getDownloadUrl(userInfo.profilePic);
        }
    }, [userInfo, isPhotoLoading]);
    var items = [
        {
            label: (_jsxs("div", __assign({ className: styles.menuItem }, { children: [_jsx("div", __assign({ className: styles.menuIconAi }, { children: _jsx(UserOutlined, { style: { fontWeight: 'bold' } }) })), _jsx("div", __assign({ className: "button__text" }, { children: t('MyProfile') }))] }))),
            key: 'profile',
            onClick: function () { return navigate('/profile'); },
        },
        {
            label: (_jsxs("div", __assign({ className: styles.menuItem }, { children: [_jsx("div", __assign({ className: styles.menuIconAi }, { children: _jsx(QuestionCircleOutlined, {}) })), _jsx("div", __assign({ className: "button__text" }, { children: t('HelpCenter') }))] }))),
            key: 'helpcenter',
            onClick: function () {
                window.open('https://help.careerflow.ai', '_blank');
            },
        },
        {
            label: (_jsxs("div", __assign({ className: styles.menuItem }, { children: [_jsx("div", __assign({ className: styles.menuIconAi }, { children: _jsx(SettingOutlined, {}) })), _jsx("div", __assign({ className: "button__text" }, { children: t('Setting') }))] }))),
            key: 'settings',
            onClick: function () { return navigate('/settings'); },
        },
        {
            label: (_jsxs("div", __assign({ className: styles.menuItem }, { children: [_jsx("div", __assign({ className: styles.menuIconAi }, { children: _jsx(LogoutOutlined, {}) })), _jsx("div", __assign({ className: "button__text" }, { children: t('Logout') }))] }))),
            key: 'logout',
            onClick: function () {
                var _a;
                logout();
                (_a = window.chrome) === null || _a === void 0 ? void 0 : _a.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
                    logout: true,
                });
            },
        },
    ];
    var coachViewItem = {
        label: (_jsxs("div", __assign({ className: styles.menuItem }, { children: [_jsx("div", __assign({ className: styles.menuIconAi }, { children: _jsx(SwapOutlined, {}) })), _jsx("div", __assign({ className: "button__text" }, { children: t('SwitchToCoachView') }))] }))),
        key: 'coach',
        // Redirect to the coach view
        onClick: function () {
            window.location.href =
                'https://coach.careerflow.ai/' +
                    isInstituteCoach +
                    '?ref=jobseeker_view';
        },
    };
    if (isInstituteCoach) {
        items.splice(1, 0, coachViewItem);
    }
    if ((_b = institute === null || institute === void 0 ? void 0 : institute.disabledFeatures) === null || _b === void 0 ? void 0 : _b.includes(InstituteFeatureFlags['help-center'])) {
        items = items.filter(function (item) { return item.key !== 'helpcenter'; });
    }
    return (_jsx("div", { children: !!user && (userInfo === null || userInfo === void 0 ? void 0 : userInfo.username) ? (_jsx(Dropdown, __assign({ trigger: ['click'], menu: { items: items }, placement: "bottomRight" }, { children: _jsxs("div", __assign({ style: { padding: '8px', cursor: 'pointer' } }, { children: [_jsxs("div", __assign({ className: styles.profileDiv, style: { display: 'flex', alignItems: 'center' } }, { children: ["".concat((userInfo === null || userInfo === void 0 ? void 0 : userInfo.fname) || 'My Profile', " ").concat(userInfo === null || userInfo === void 0 ? void 0 : userInfo.lname), profilePic ? (_jsx("img", { src: profilePic, alt: "profile", height: "25", width: "25", className: styles.avatar })) : (_jsx("img", { src: profileIcon, alt: "profile icon", height: "25", width: "25", className: styles.avatar, style: { background: 'white', border: 'none' } })), _jsx(DownOutlined, { size: 12, style: { color: '#96A0B5', marginLeft: '6px' } })] })), _jsx("div", {})] })) }))) : profilePic === '' && !!user ? (_jsx(Spin, { style: { marginRight: '2rem' } })) : (_jsxs("div", __assign({ style: { fontSize: '14px' }, className: "button button--empty", onClick: function () { return signUpOrSignInWithGoogleCall('webapp'); }, onKeyDown: function (e) {
                return handleOnKeyDown(e, function () { return signUpOrSignInWithGoogleCall('webapp'); });
            }, tabIndex: 0, role: "button" }, { children: [_jsx("img", { className: "img-icon", src: GooggleIcon, alt: "google-login", style: {
                        height: '20px',
                        width: '20px',
                        marginRight: '2px',
                    } }), _jsx("strong", { children: t('SignIn') })] }))) }));
};
export default ProfileInfo;
