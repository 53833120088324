import React from 'react';
import './SkipLink.less';
var SkipLink = function (props) {
    var onClick = function (event) {
        event.preventDefault();
        var container = document.querySelector(props.skipTo);
        if (container) {
            container.tabIndex = -1;
            container.focus();
            setTimeout(function () { return container.removeAttribute('tabindex'); }, 1000);
        }
    };
    return React.cloneElement(props.children, {
        onClick: onClick,
        className: props.className,
    });
};
export default SkipLink;
